import store from "@/state/store";
import { roles } from "../config/roles";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/test/draw",
    name: "Draw",
    meta: { authRequired: false, permissions: roles.DriversAll },
    component: () => import("../views/pages/terminals/draw.vue"),
  },
  {
    path: "/resetPassword/<id>",
    name: "register",
    component: () => import("../views/pages/account/reset-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },

  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/resetPassword/:id",
    name: "resetPassword",
    component: () => import("../views/pages/account/reset-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/resetUserPassword/:id",
    name: "resetUserPassword",
    component: () => import("../views/pages/account/reset-user-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/resetSucess",
    name: "resetSucess",
    component: () => import("../views/pages/account/reset-sucess"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/logout");

        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: "home" } : { ...routeFrom });
      },
    },
  },
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
      permissions: roles.home,
    },
    component: () => import("@/views/pages/vehiclesAd"),
  },
  {
    path: "/dashboard_home",
    name: "home",
    meta: {
      authRequired: true,
      permissions: roles.home,
    },
    component: () => import("../views/pages/dashboard/index"),
  },
  {
    path: "/clustermaps",
    name: "ClusterMaps",
    meta: {
      authRequired: true,
      permissions: roles.home,
    },
    component: () => import("../views/pages/dashboard/cluster-map.vue"),
  },
  {
    path: "/not-authorized",
    name: "NotAuthorized",
    meta: {
      authRequired: false,
      permissions: roles.all,
    },
    component: () => import("../views/pages/NotAuthorized"),
  },
  {
    path: "/users/profile",
    name: "UserProfile",
    meta: { authRequired: true, permissions: roles.UserProfile },
    component: () => import("../views/pages/users/profile"),
  },
  {
    path: "/users/all",
    name: "UsersAll",
    meta: { authRequired: true, permissions: roles.UsersAll },
    component: () => import("../views/pages/users/users"),
  },

  {
    path: "/users/otp",
    name: "otpAll",
    meta: { authRequired: true, permissions: roles.UsersAll },
    component: () => import("../views/pages/otp/otp"),
  },

  {
    path: "/users/roles",
    name: "UserRoles",
    meta: { authRequired: true, permissions: roles.UserRoles },
    component: () => import("../views/pages/users/roles"),
  },
  {
    path: "/users/permissions",
    name: "UsersPermissions",
    meta: { authRequired: true, permissions: roles.UsersPermissions },
    component: () => import("../views/pages/users/permissions"),
  },
  {
    path: "/drivers/all",
    name: "DriversAll",
    meta: { authRequired: true, permissions: roles.DriversAll },
    component: () => import("../views/pages/driver"),
  },
  {
    path: "/drivers/profile/:id",
    name: "DriverDetails",
    meta: { authRequired: true, permissions: roles.DriverDetails },
    props: true,
    component: () => import("../views/pages/driver/profile"),
  },
  {
    path: "/advertisers/all",
    name: "AdvertisersAll",
    meta: { authRequired: true, permissions: roles.AdvertisersAll },
    component: () => import("../views/pages/advertiser"),
  },
  {
    path: "/advertisers/ad_information/:campaignId",
    name: "CampaignMapAnalytics",
    meta: { authRequired: true, permissions: roles.AdvertisersAll },
    component: () => import("../views/pages/advertiser/map-analytics"),
  },
  {
    path: "/advertisers/profile/:id",
    name: "AdvertiserDetails",
    meta: { authRequired: true, permissions: roles.AdvertiserDetails },
    props: true,
    component: () => import("../views/pages/advertiser/profile"),
  },
  {
    path: "/locations",
    name: "Locations",
    meta: { authRequired: true, permissions: roles.Location },
    component: () => import("../views/pages/location"),
  },

  {
    path: "/drivers/vehicle/:id",
    name: "VehicleInfo",
    meta: { authRequired: true, permissions: roles.VehicleInfo },
    props: true,
    component: () => import("../views/pages/driver/vehicleinfo"),
  },
  {
    path: "/plans/all",
    name: "PlansAll",
    meta: { authRequired: true, permissions: roles.PlansAll },
    component: () => import("../views/pages/plans/plans"),
  },
  {
    path: "/txn/all",
    name: "Transactions",
    meta: { authRequired: true, permissions: roles.Transactions },
    component: () => import("../views/pages/transcations/index"),
  },
  {
    path: "/banks/all",
    name: "BanksAll",
    meta: { authRequired: true, permissions: roles.BanksAll },
    component: () => import("../views/pages/banks/banks"),
  },
  {
    path: "/vehicle/models/all",
    name: "VehicleModelsAll",
    meta: { authRequired: true, permissions: roles.VehicleModelsAll },
    component: () => import("../views/pages/vehicle/models/index"),
  },
  {
    path: "/vehicle/manufactures/all",
    name: "VehicleManufacturesAll",
    meta: { authRequired: true, permissions: roles.VehicleManufacturesAll },
    component: () => import("../views/pages/vehicle/manufactures/index"),
  },

  {
    path: "/campaigns/all",
    name: "CampaignsAll",
    meta: { authRequired: true, permissions: roles.CampaignsAll },
    component: () => import("../views/pages/campaigns"),
  },

  {
    path: "/displaydevices/all",
    name: "DisplayDevicesAll",
    meta: { authRequired: true, permissions: roles.DisplayDevicesAll },
  component: () => import("../views/pages/display_devices"),
  },

  {
    path: "/displayads/all",
    name: "DisplayAdsAll",
    meta: { authRequired: true, permissions: roles.DisplayAdsAll },
  component: () => import("../views/pages/display_ads"),
  },

  {
    path: "/displayAds/:id",
    name: "displayAdsDetails",
    props: true,
    meta: { authRequired: true, permissions: roles.DisplayDevicesAll },
    component: () => import("../views/pages/display_ads/details.vue"),
  },

  {
    path: "/campaigns/payment/all",
    name: "CampaignsPaymentAll",
    meta: { authRequired: true, permissions: roles.CampaignsAll },
    component: () => import("../views/pages/campaigns/payment"),
  },
  {
    path: "/campaigns/partial-complete",
    name: "CampaignsPartialComplete",
    meta: { authRequired: true, permissions: roles.CampaignsPartialComplete },
    component: () => import("../views/pages/campaigns/unassigned"),
  },
  {
    path: "/campaigns/:id",
    name: "CampaignsDetails",
    props: true,
    meta: { authRequired: true, permissions: roles.CampaignsDetails },
    component: () => import("../views/pages/campaigns/details"),
  },

  {
    path: "/displayDevices/:id",
    name: "displayDevicesDetails",
    props: true,
    meta: { authRequired: true, permissions: roles.DisplayDevicesAll },
    component: () => import("../views/pages/display_devices/details.vue"),
  },

  {
    path: "/regions/:id",
    name: "RegionDetails",
    props: true,
    meta: { authRequired: true, permissions: roles.RegionDetails },
    component: () => import("../views/pages/region/details"),
  },
  {
    path: "/campaigns/verify",
    name: "VerifyCampaign",
    meta: { authRequired: true, permissions: roles.VerifyCampaign },
    component: () => import("@/views/pages/campaigns/campaigns"),
  },
  {
    path: "/campaigns/upload-design",
    name: "UploadDesign",
    meta: { authRequired: true, permissions: roles.UploadDesign },
    component: () => import("@/views/pages/campaigns/campaigns"),
  },
  {
    path: "/campaigns/printed-campaigns",
    name: "PrintedCampaigns",
    meta: { authRequired: true, permissions: roles.PrintedCampaigns },
    component: () => import("@/views/pages/campaigns/campaigns"),
  },
  {
    path: "/company/details/:id",
    name: "CompanyDetails",
    meta: { authRequired: true, permissions: roles.CompanyDetails },
    props: true,
    component: () => import("@/views/pages/company/details"),
  },
  {
    path: "/fcm",
    name: "FcmIndex",
    meta: { authRequired: true, permissions: roles.FcmIndex },
    props: true,
    component: () => import("@/views/pages/fcm"),
  },
  {
    path: "/workstations",
    name: "Workstations",
    meta: { authRequired: true, permissions: roles.Workstations },
    props: true,
    component: () => import("@/views/pages/workstations"),
  },
  {
    path: "/workstation",
    name: "Workstation",
    meta: { authRequired: true, permissions: roles.Workstation },
    props: true,
    component: () => import("@/views/pages/workstations/informations"),
  },

  // For designers
  {
    path: "/designers/campaigns/all",
    name: "allCampaignDesigners",
    meta: { authRequired: true, permissions: roles.allCampaignDesigners },
    component: () => import("@/views/pages/designers/viewCampaigns"),
  },
  {
    path: "/designers/campaign/:id",
    name: "DesignerCampaignsDetails",
    meta: { authRequired: true, permissions: roles.CampaignsDetails },
    component: () => import("../views/pages/designers/campaignDetails"),
  },
  {
    path: "/banner",
    name: "Banner",
    meta: { authRequired: true, permissions: roles.Workstation },
    props: true,
    component: () => import("@/views/pages/banner/index"),
  },
  {
    path: "/tickets",
    name: "Tickets",
    meta: { authRequired: true, permissions: roles.Tickets },
    props: true,
    component: () => import("@/views/pages/tickets/index"),
  },
  {
    path: "/ticket-comments/:ticket_id",
    name: "TicketComments",
    meta: { authRequired: true, permissions: roles.TicketComments },
    props: true,
    component: () => import("@/views/pages/tickets/ticket-comments"),
  },
  // For partner
  {
    path: "/partner/jobs/:id?",
    name: "ScheduledJob",
    meta: { authRequired: true, permissions: roles.ScheduledJob },
    props: true,
    component: () => import("@/views/partner/ScheduledJob.vue"),
  },
  {
    path: "/partner/job/wrapping-msg",
    name: "WrappingMessages",
    meta: { authRequired: true, permissions: roles.ScheduledJob },
    props: true,
    component: () => import("@/views/partner/WrappingMessages.vue"),
  },
  {
    path: "/partner/job/finish/:id",
    name: "FinishJob",
    meta: { authRequired: true, permissions: roles.JobDetailsForPartner },
    component: () => import("@/views/partner/FinishJob.vue"),
  },
  {
    path: "/partner/inspect",
    name: "InspectVehicle",
    meta: { authRequired: true, permissions: roles.InspectVehicle },
    component: () => import("@/views/partner/InspectVehicle.vue"),
  },
  {
    path: "/partner/work-time",
    name: "AdjustWorkingTime",
    meta: { authRequired: true, permissions: roles.AdjustWorkingTime },
    component: () => import("@/views/partner/AdjustWorkingTime.vue"),
  },
  {
    path: "/partner/job/details/:id",
    name: "JobDetailsForPartner",
    meta: { authRequired: true, permissions: roles.JobDetailsForPartner },
    component: () => import("@/views/partner/JobDetails.vue"),
  },
  {
    path: "/partner/inspect/action/:id",
    name: "InspectVehicleAction",
    meta: { authRequired: true, permissions: roles.JobDetailsForPartner },
    component: () => import("@/views/partner/InspectVehicleAction.vue"),
  },
  {
    path: "/company_banner",
    name: "Company Banner",
    meta: { authRequired: true, permissions: roles.Workstation },
    props: true,
    component: () => import("@/views/pages/company_banner/index"),
  },
  {
    path: "/inspections/all",
    name: "InspectionsAll",
    meta: { authRequired: true, permissions: roles.DriversAll },
    component: () => import("../views/pages/inspections"),
  },
  {
    path: "/terminals/all",
    name: "TerminalsAll",
    meta: { authRequired: true, permissions: roles.DriversAll },
    component: () => import("../views/pages/terminals"),
  },
  {
    path: "/terminals/live",
    name: "TrackDriver",
    meta: { authRequired: true, permissions: roles.DriversAll },
    component: () => import("../views/pages/terminals/livetracking"),
  },

  {
    path: "/inspections/details/:id",
    name: "InspectionDetails",
    meta: { authRequired: true, permissions: roles.DriversAll },
    component: () => import("../views/pages/inspections/details"),
  },

  {
    path: "/unpaid-campaigns",
    name: "UnpaidCampaigns",
    meta: { authRequired: true, permissions: roles.UnpaidCampaign },
    props: true,
    component: () => import("@/views/pages/unpaid_campaign"),
  },

  {
    path: "/region",
    name: "Region",
    meta: { authRequired: true, permissions: roles.UnpaidCampaign },
    props: true,
    component: () => import("@/views/pages/region"),
  },

  {
    path: "/city",
    name: "City",
    meta: { authRequired: true, permissions: roles.UnpaidCampaign },
    props: true,
    component: () => import("@/views/pages/city"),
  },

  {
    path: "/vehicles-ad",
    name: "VehiclesAd",
    meta: { authRequired: true, permissions: roles.VehicleAd },
    props: true,
    component: () => import("@/views/pages/vehiclesAd"),
  },
  {
    path: "/vehicles-company",
    name: "VehiclesCompany",
    meta: { authRequired: true, permissions: roles.VehicleCompany },
    props: true,
    component: () => import("@/views/pages/vehiclesCompany"),
  },
  {
    path: "/troubleshoot",
    name: "Troubleshoot",
    meta: { authRequired: true, permissions: roles.Troubleshoot },
    props: true,
    component: () => import("@/views/pages/troubleshoot"),
  },

  /// --- not implemented ---
  {
    path: "/chat",
    name: "Chat",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/chat/index"),
  },
  {
    path: "/calendar",
    name: "Calendar",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/calendar/index"),
  },
  {
    path: "/ecommerce/products",
    name: "products",
    meta: { authRequired: true },
    component: () => import("../views/pages/ecommerce/products"),
  },
  {
    path: "/ecommerce/product-detail",
    name: "product detail",
    meta: { authRequired: true },
    component: () => import("../views/pages/ecommerce/product-detail"),
  },
  {
    path: "/ecommerce/orders",
    name: "Orders",
    meta: { authRequired: true },
    component: () => import("../views/pages/ecommerce/orders"),
  },
  {
    path: "/ecommerce/customers",
    name: "Customers",
    meta: { authRequired: true },
    component: () => import("../views/pages/ecommerce/customers"),
  },
  {
    path: "/ecommerce/cart",
    name: "Cart",
    meta: { authRequired: true },
    component: () => import("../views/pages/ecommerce/cart"),
  },
  {
    path: "/ecommerce/checkout",
    name: "Checkout",
    meta: { authRequired: true },
    component: () => import("../views/pages/ecommerce/checkout"),
  },
  {
    path: "/ecommerce/shops",
    name: "Shops",
    meta: { authRequired: true },
    component: () => import("../views/pages/ecommerce/shops"),
  },
  {
    path: "/ecommerce/add-product",
    name: "Add-product",
    meta: { authRequired: true },
    component: () => import("../views/pages/ecommerce/add-product"),
  },
  {
    path: "/email/inbox",
    name: "Inbox",
    meta: { authRequired: true },
    component: () => import("../views/pages/email/inbox"),
  },
  {
    path: "/email/read",
    name: "Read Email",
    meta: { authRequired: true },
    component: () => import("../views/pages/email/reademail"),
  },
  {
    path: "/auth/login-1",
    name: "login-1",
    meta: { authRequired: true },
    component: () => import("../views/pages/sample-auth/login-1"),
  },
  {
    path: "/auth/register-1",
    name: "register-1",
    meta: { authRequired: true },
    component: () => import("../views/pages/sample-auth/register-1"),
  },
  {
    path: "/auth/lock-screen-1",
    name: "Lock-screen-1",
    meta: { authRequired: true },
    component: () => import("../views/pages/sample-auth/lock-screen-1"),
  },
  {
    path: "/auth/recoverpwd-1",
    name: "Recoverpwd-1",
    meta: { authRequired: true },
    component: () => import("../views/pages/sample-auth/recoverpwd-1"),
  },

  {
    path: "/pages/starter",
    name: "Starter Page",
    meta: { authRequired: true },
    component: () => import("../views/pages/utility/starter"),
  },
  {
    path: "/pages/maintenance",
    name: "Maintenance",
    meta: { authRequired: true },
    component: () => import("../views/pages/utility/maintenance"),
  },
  {
    path: "/pages/coming-soon",
    name: "Coming-soon",
    meta: { authRequired: true },
    component: () => import("../views/pages/utility/coming-soon"),
  },
  {
    path: "/pages/timeline",
    name: "Timeline",
    meta: { authRequired: true },
    component: () => import("../views/pages/utility/timeline/index"),
  },
  {
    path: "/pages/faqs",
    name: "Faqs",
    meta: { authRequired: true },
    component: () => import("../views/pages/utility/faqs"),
  },
  {
    path: "/pages/pricing",
    name: "Pricing",
    meta: { authRequired: true },
    component: () => import("../views/pages/utility/pricing/index"),
  },
  {
    path: "/pages/error-404",
    name: "Error-404",
    meta: { authRequired: true },
    component: () => import("../views/pages/utility/error-404"),
  },
  {
    path: "/pages/error-500",
    name: "Error-500",
    meta: { authRequired: true },
    component: () => import("../views/pages/utility/error-500"),
  },
  {
    path: "/icons/font-awesome",
    name: "Font Awesome 5",
    meta: { authRequired: true },
    component: () => import("../views/pages/icons/font-awesome/index"),
  },
  {
    path: "/icons/dripicons",
    name: "Dripicons",
    meta: { authRequired: true },
    component: () => import("../views/pages/icons/dripicons"),
  },
  {
    path: "/icons/material-design",
    name: "Material Design",
    meta: { authRequired: true },
    component: () => import("../views/pages/icons/materialdesign/index"),
  },
  {
    path: "/icons/remix",
    name: "Remix Icons",
    meta: { authRequired: true },
    component: () => import("../views/pages/icons/remix/index"),
  },
  {
    path: "/ui/buttons",
    name: "Buttons",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/buttons"),
  },
  {
    path: "/ui/alerts",
    name: "Alerts",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/alerts"),
  },
  {
    path: "/ui/grid",
    name: "Grid",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/grid"),
  },
  {
    path: "/ui/cards",
    name: "Cards",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/cards"),
  },
  {
    path: "/ui/carousel",
    name: "Carousel",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/carousel"),
  },
  {
    path: "/ui/dropdowns",
    name: "Dropdowns",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/dropdowns"),
  },
  {
    path: "/ui/images",
    name: "Images",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/images"),
  },
  {
    path: "/ui/modals",
    name: "Modals",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/modals"),
  },
  {
    path: "/ui/rangeslider",
    name: "Range - slider",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/rangeslider"),
  },
  {
    path: "/ui/progressbar",
    name: "Progressbar",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/progressbars"),
  },
  {
    path: "/ui/sweet-alert",
    name: "Sweet-alert",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/sweet-alert"),
  },
  {
    path: "/ui/tabs-accordion",
    name: "Tabs & Accordion",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/tabs-accordions"),
  },
  {
    path: "/ui/typography",
    name: "Typography",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/typography"),
  },
  {
    path: "/ui/video",
    name: "Video",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/video"),
  },
  {
    path: "/ui/general",
    name: "General",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/general"),
  },
  {
    path: "/ui/lightbox",
    name: "Lightbox",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/lightbox"),
  },
  {
    path: "/ui/notification",
    name: "Notification",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/notification"),
  },
  {
    path: "/ui/rating",
    name: "Rating",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/rating"),
  },
  {
    path: "/ui/session-timeout",
    name: "Session Timeout",
    meta: { authRequired: true },
    component: () => import("../views/pages/ui/session-timeout"),
  },
  {
    path: "/form/elements",
    name: "Form Elements",
    meta: { authRequired: true },
    component: () => import("../views/pages/forms/elements"),
  },
  {
    path: "/form/validation",
    name: "Form validation",
    meta: { authRequired: true },
    component: () => import("../views/pages/forms/validation"),
  },
  {
    path: "/form/advanced",
    name: "Form Advanced",
    meta: { authRequired: true },
    component: () => import("../views/pages/forms/advanced"),
  },
  {
    path: "/form/editor",
    name: "CK Editor",
    meta: { authRequired: true },
    component: () => import("../views/pages/forms/ckeditor"),
  },
  {
    path: "/form/uploads",
    name: "File Uploads",
    meta: { authRequired: true },
    component: () => import("../views/pages/forms/uploads"),
  },
  {
    path: "/form/wizard",
    name: "Form Wizard",
    meta: { authRequired: true },
    component: () => import("../views/pages/forms/wizard"),
  },
  {
    path: "/form/mask",
    name: "Form Mask",
    meta: { authRequired: true },
    component: () => import("../views/pages/forms/mask"),
  },
  {
    path: "/tables/basic",
    name: "Basic Tables",
    meta: { authRequired: true },
    component: () => import("../views/pages/tables/basictable"),
  },
  {
    path: "/tables/advanced",
    name: "Advanced Tables",
    meta: { authRequired: true },
    component: () => import("../views/pages/tables/advancedtable"),
  },
  {
    path: "/charts/apex",
    name: "Apex chart",
    meta: { authRequired: true },
    component: () => import("../views/pages/charts/apex"),
  },
  {
    path: "/charts/chartjs",
    name: "Chartjs chart",
    meta: { authRequired: true },
    component: () => import("../views/pages/charts/chartjs/index"),
  },
  {
    path: "/charts/chartist",
    name: "Chartist chart",
    meta: { authRequired: true },
    component: () => import("../views/pages/charts/chartist"),
  },
  {
    path: "/charts/echart",
    name: "Echart chart",
    meta: { authRequired: true },
    component: () => import("../views/pages/charts/echart/index"),
  },
  {
    path: "/maps/google",
    name: "Google Maps",
    meta: { authRequired: true },
    component: () => import("../views/pages/maps/google"),
  },
  {
    path: "/maps/leaflet",
    name: "Leaflet Maps",
    meta: { authRequired: true },
    component: () => import("../views/pages/maps/leaflet/index"),
  },
];
