import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import "@/assets/scss/plugins/vueformulate.scss";
import { TagsInputPlugin } from "@/vueformulate";

import { API_ENDPOINT } from "@/config/axios";

Vue.use(VueFormulate, {
  plugins: [TagsInputPlugin],
  uploader: async function (file, progress, error) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const result = await fetch(`${API_ENDPOINT}file/upload`, {
        headers: {
        
          Authorization: JSON.parse(localStorage.getItem("Access-Token")),
        },
        method: "POST",
        body: formData,
      });
      progress(100); // (native fetch doesn’t support progress updates)
      return await result.json();
    } catch (err) {
      error("Unable to upload file");
    }
  },
});
