import axios from "axios";

// const API_ENDPOINT =
//   process.env.NODE_ENV == "staging"
//     ? "https://bseenmvp.herokuapp.com/"
//     : process.env.NODE_ENV == "test"
//     ? "https://test.api.b-seenapp.com/"
//     : process.env.NODE_ENV == "dev"
//     ? "https://test.api.b-seenapp.com/"
//     : "https://api.b-seenapp.com/";
const API_ENDPOINT = "https://api.b-seenapp.com/";
// const API_ENDPOINT = "https://test.api.b-seenapp.com/";


// const API_ENDPOINT = "https://test.api.b-seenapp.com/"
// const API_ENDPOINT = "http://localhost:3000/";

export default API_ENDPOINT;

const client = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

const client_multipart = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

/*eslint-disable */
class DataService {
  static get(path = "", id = null, params = "") {
    return client({
      method: "GET",
      url: path + `${id ? id : ""}`,
      params,
    });
  }

  static multipartPost(path = "", data = {}, optionalHeader = {}) {
    return client_multipart({
      method: "POST",
      url: path,
      data,
    });
  }

  static post(path = "", data = {}, optionalHeader = {}) {
    console.log(path, data);
    return client({
      method: "POST",
      url: path,
      data,
    });
  }

  static patch(path = "", id = null, data = {}) {
    return client({
      method: "PATCH",
      url: path + `${id ? id : ""}`,
      data: JSON.stringify(data),
    });
  }

  static delete(path = "", id = null) {
    return client({
      method: "DELETE",
      url: path + `${id ? id : ""}`,
    });
  }
  static postid(path = "", id = null, data = {}) {
    return client({
      method: "POST",
      url: path + `${id ? id : ""}`,
      data,
    });
  }
  static put(path = "", id = null, data = {}) {
    return client({
      method: "PUT",
      url: path + `${id ? id : ""}`,
      data,
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  const token = JSON.parse(localStorage.getItem("Access-Token"));
  requestConfig.headers = {
    ...headers,
    Authorization: token,
  };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    // if (response) {
    //     if (response.status === 500) {
    //         // do something here
    //     } else {
    //         return originalRequest;
    //     }
    // }
    return Promise.reject(error);
  }
);

class MultiPartDataService {
  static post(path = "", form = null, progress = null) {
    return client({
      method: "POST",
      url: path,
      data: form,
      onUploadProgress: (progressEvent) => {
        progress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    });
  }
}

/*eslint-enable */
export { DataService, MultiPartDataService, API_ENDPOINT };
