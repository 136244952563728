// This is for roles
export default {
  all: ["Super Admin", "Designer", "partner"],
  home: ["Super Admin"],
  UserProfile: ["Super Admin"],
  UsersAll: ["Super Admin"],
  UserRoles: ["Super Admin"],
  UsersPermissions: ["Super Admin"],
  DriversAll: ["Super Admin"],
  DriverDetails: ["Super Admin"],
  VehicleInfo: ["Super Admin"],
  PlansAll: ["Super Admin"],
  Transactions: ["Super Admin"],
  BanksAll: ["Super Admin"],
  VehicleModelsAll: ["Super Admin"],
  VehicleManufacturesAll: ["Super Admin"],
  CampaignsAll: ["Super Admin"],
  DisplayDevicesAll: ["Super Admin"],
  DisplayAdsAll: ["Super Admin"],
  CampaignsPartialComplete: ["Super Admin"],
  CampaignsDetails: ["Super Admin"],
  VerifyCampaign: ["Super Admin"],
  UploadDesign: ["Super Admin"],
  PrintedCampaigns: ["Super Admin"],
  CompanyDetails: ["Super Admin"],
  FcmIndex: ["Super Admin"],
  Workstations: ["Super Admin"],
  Workstation: ["Super Admin"],
  Banner: ["Super Admin"],
  Tickets: ["Super Admin", "Designer", "partner"],
  TicketComments: ["Super Admin", "Designer", "partner"],
  allCampaignDesigners: ["Designer"],
  ScheduledJob: ["partner"],
  JobDetailsForPartner: ["partner"],
  InspectVehicle: ["partner"],
  AdjustWorkingTime:["partner"],
  AdvertiserAll: ["Super Admin"],
  Location:["Super Admin"],
  UnpaidCampaign:["Super Admin"],
  VehicleAd:["Super Admin"],
  VehicleCompany:["Super Admin"],
  Troubleshoot:["Super Admin"],
};
