import TagsInput from "./tags-input";
import TagsInputSearch from "./tags-input-search";
import MapInput from "./map-input";
import CustomFile from "./custom-file";

export function TagsInputPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      TagsInput,
      TagsInputSearch,
      MapInput,
      CustomFile,
    },
    library: {
      "tags-input": {
        classification: "search",
        component: "TagsInput",
      },
      "tags-input-search": {
        classification: "search",
        component: "TagsInputSearch",
      },
      "map-input": {
        classification: "map",
        component: "MapInput",
      },
      "file-input": {
        classification: "file",
        component: "CustomFile",
      },
    },
  });
}
