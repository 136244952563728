import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import GmapPolyline from "vue2-google-maps/dist/components/polyline";
import GmapPolygon from "vue2-google-maps/dist/components/polygon";

import VueYoutube from "vue-youtube";
import VueGoogleHeatmap from "vue-google-heatmap";

import vco from "v-click-outside";

import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";

import "@/assets/scss/app.scss";

import { initFirebaseBackend } from "./helpers/firebase/authUtils";

import { configureFakeBackend } from "./helpers/fakebackend/fake-backend";

import "@/plugins/vue-formulate";
import "@/plugins/api-gen";
import "@/plugins/moment";
import "vue-search-select/dist/VueSearchSelect.css";
import ToggleButton from "vue-js-toggle-button";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

Vue.component('v-select', vSelect)
Vue.config.productionTip = false;
Vue.use(VueYoutube);
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(require("vue-chartist"));
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDvCb2nn_O8dzSkOJKYCgpFliXcfwMekOQ",
    libraries: "places",
  },
  installComponents: true,
});
Vue.use(VueGoogleHeatmap, {
  apiKey: "AIzaSyDvCb2nn_O8dzSkOJKYCgpFliXcfwMekOQ",
});
Vue.use(ToggleButton);
Vue.component("GmapCluster", GmapCluster);
Vue.component("GmapPolyline", GmapPolyline);
Vue.component("GmapPolygon", GmapPolygon);
Vue.component("apexchart", VueApexCharts);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
