// Api for user crud
// Instruction add logical segment of api
// Add opration and add required keys
/*
   post=>{
        url:'<url>',
        method:'POST',
        data:<data>
    }
    update=>{
        url:'<url>',
        method:'PUT',
        id:<id>,
        data:<data>
    }
    get=>{
        url:'<url>',
        method:'GET',
        id:<id>,
        params:<params>
    }
    delete=>{
        url:'<url>',
        method:'DELETE',
        id:<id>
    }

*/



export const auth={
    login:{
        url:'admin/user/login',
        method:'POST',
        data:null
    },
}





