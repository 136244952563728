import { userService } from "../../helpers/fakebackend/user.service";
import router from "../../router/index";
import { auth } from "@/config/api";
import { saveState, removeState } from "@/utils/localStorage";

const user = JSON.parse(localStorage.getItem("user"));
export const state = user
  ? { status: { loggeduser: true }, user }
  : { status: {}, user: null };

export const actions = {
  // Logs in the user.
  // eslint-disable-next-line no-unused-vars
  login({ dispatch, commit }, { email, password, generateAPI }) {
    commit("loginRequest", { email });
    const api = auth.login;
    api.data = {
      email: email,
      password: password,
    };
    return generateAPI(api)
      .then((res) => {
        if (res.data.isAuth) {
          commit("loginSuccess", res.data);
          saveState("user", res.data);
          saveState("Access-Token", res.data.token);
          if (state.user.user.roles[0].name === "Super Admin") {
            router.push({ name: "home" });
          } else if (state.user.user.roles[0].name === "partner") {
            router.push({ name: "ScheduledJob", params: { id: 1 } });
          } else {
            router.push({ name: "allCampaignDesigners" });
          }
        } else {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      });
  },
  // Logout the user
  logout({ commit }) {
    userService.logout();
    console.log("Please logout!");
    removeState("Access-Token");
    commit("logout");
  },
  // register the user
  registeruser({ dispatch, commit }, user) {
    commit("registerRequest", user);
    userService.register(user).then(
      (user) => {
        commit("registerSuccess", user);
        dispatch("notification/success", "Registration successful", {
          root: true,
        });
        router.push("/login");
      },
      (error) => {
        commit("registerFailure", error);
        dispatch("notification/error", error, { root: true });
      }
    );
  },
};

export const getters = {
  userPermissions(state) {
    return (
      (state.user && state.user.user && state.user.user.roles[0].permissions) ||
      []
    );
  },
  userRole(state) {
    return (
      (state.user && state.user.user && state.user.user.roles[0].name) || []
    );
  },
  workStation(state) {
    return (
      (state.user && state.user.user && state.user.user.work_station) || ""
    );
  },
};

export const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggeduser: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
  registerRequest(state) {
    state.status = { registering: true };
  },
  registerSuccess(state) {
    state.status = {};
  },
  registerFailure(state) {
    state.status = {};
  },
};
