export const state = {
  displayAds: null,
  currentPage: 1,
  currentTab: 0,
  rows:0,
};

export const getters = {
  currentTab(state) {
    return state.currentTab;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
};

// export const mutations = {
//   SELECT_CAMPAIGN(state, displayAd) {
//     state.displayAds = displayAd;
//   },
//   UPDATE_CURRENT_PAGE(state, page) {
//     state.currentPage = page;
//   },
//   UPDATE_CURRENT_TAB(state, tab) {
//     state.currentTab = tab;
//   },
//   CHANGE_CAMPAIGN_STATUS(state, status) {
//     state.displayAds ? (state.displayAds.status = status) : "";
//   },
//   CHANGE_TOTAL_ROWS(state, total) {
//     state.rows = total 
//   },
// };

// export const actions = {
//   selectCampaign({ commit }, displayAd) {
//     commit("SELECT_CAMPAIGN", displayAd);
//   },
//   changePage({ commit }, page) {
//     commit("UPDATE_CURRENT_PAGE", page);
//   },
//   changeTab({ commit }, tab) {
//     commit("UPDATE_CURRENT_TAB", tab);
//   },
//   changeCampaignState({ commit }, status) {
//     commit("CHANGE_CAMPAIGN_STATUS", status);
//   },
//   changeTotalRow({ commit }, total) {
//     commit("CHANGE_TOTAL_ROWS", total);
//   },
// };
